import React, { useRef, forwardRef, useEffect } from 'react'
import { useChat } from '../../contexts/chat/useChat'
import { useMentions } from '../../hooks/useMentions'
import SuggestionDropdown from '../mention/SuggestionDropdown'

const PostTextarea = forwardRef(({ emojiData, innerRef, ...props }, ref) => {
  const textareaRef = useRef(null)
  const { chatClient } = useChat()
  const {
    text,
    handleInput,
    handleKeyDown,
    filteredSuggestions,
    showDropdown,
    activeSuggestionIndex,
    insertMention,
  } = useMentions(chatClient)

  const adjustHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }

  useEffect(() => {
    console.log('Text:', text)
    adjustHeight()
  }, [text])

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <textarea
        {...props}
        value={text}
        placeholder="Share a flight!"
        className="rta__textarea"
        ref={(node) => {
          textareaRef.current = node
          if (ref) ref.current = node
        }}
        style={{ height: 'auto', flex: 1 }}
        onInput={(e) => {
          handleInput(e.target.value)
          adjustHeight()
        }}
        onKeyDown={handleKeyDown}
      />
      {showDropdown && (
        <SuggestionDropdown
          suggestions={filteredSuggestions}
          activeIndex={activeSuggestionIndex}
          onClickSuggestion={(user) => {
            insertMention(user)
            textareaRef.current.focus()
          }}
        />
      )}
    </div>
  )
})

export default PostTextarea
