import React from 'react'
import styled from 'styled-components'

import UserImage from '../profile/UserImage'

const SuggestionDropdown = ({
  suggestions,
  activeIndex,
  onClickSuggestion,
}) => {
  return (
    <DropdownContainer>
      {suggestions.map((user, index) => (
        <DropdownItem
          key={user.id}
          className={index === activeIndex ? 'active' : ''}
          onClick={() => onClickSuggestion(user)}
        >
          <div className="user">
            <UserImage
              src={user.profile?.image}
              alt={user.name}
              clickable={false}
              userId={user.id}
            />
          </div>
          <UserDetails>
            <span className="name">{user.name}</span>
            <span className="username">@{user.username}</span>
          </UserDetails>
        </DropdownItem>
      ))}
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  position: absolute;
  background-color: #222;
  border: 1px solid #333;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  max-width: 400px;
  z-index: 10;
  top: 36px;
  margin-top: 5px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.8), 0 6px 20px rgba(0, 0, 0, 0.7);
`

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  color: #fff;
  &:hover,
  &.active {
    background-color: #333;
  }

  .user {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
`

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;

  .name {
    font-weight: bold;
    color: #fff;
  }

  .username {
    color: #aaa;
    font-size: 0.9em;
  }
`

export default SuggestionDropdown
