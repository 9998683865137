import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useFeed } from '../../contexts/feed/useFeed'
import { useChat } from '../../contexts/chat/useChat'

import { useMentions } from '../../hooks/useMentions'
import SuggestionDropdown from '../mention/SuggestionDropdown'
import UserImage from '../profile/UserImage'

const Container = styled.div`
  width: 100%;

  .reply-to {
    font-size: 14px;
    color: #888;
    display: flex;
    margin-left: 55px;
    margin-bottom: 10px;

    &--name {
      margin-left: 4px;
      color: var(--theme-color);
    }
  }
`

const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: ${({ inline }) => (inline ? 'center' : 'initial')};
  position: relative;

  .user {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  .input-section {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
    align-items: ${({ inline }) => (inline ? 'center' : 'initial')};
    height: ${({ inline, minHeight }) => (inline ? '40px' : minHeight)};

    textarea {
      padding-top: 10px;
      background: none;
      border: none;
      padding-bottom: 0;
      font-size: 18px;
      width: 100%;
      flex: 1;
      resize: none;
      outline: none;
      color: white;
    }

    .actions {
      margin-top: ${({ inline }) => (inline ? '0' : 'auto')};
      display: flex;
      height: 50px;
      align-items: center;

      button {
        &:disabled {
          opacity: 0.5;
        }
      }

      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }

      .submit-btn {
        background-color: var(--theme-color);
        padding: 10px 20px;
        color: black;
        border-radius: 30px;
        margin-left: auto;
        font-weight: bold;
        font-size: 16px;

        &:disabled {
          opacity: 0.6;
        }
      }
    }
  }
`

export default function ThreadForm({
  submitText = 'Post',
  onSubmit,
  className,
  placeholder,
  collapsedOnMount = false,
  minHeight = 120,
  shouldFocus = false,
  replyingTo = null,
}) {
  const inputRef = useRef(null)
  const { feedUser } = useFeed()
  const { chatClient } = useChat()

  const [expanded, setExpanded] = useState(!collapsedOnMount)
  const {
    text,
    setText,
    handleInput,
    handleKeyDown,
    filteredSuggestions,
    showDropdown,
    activeSuggestionIndex,
    insertMention,
  } = useMentions(chatClient)

  useEffect(() => {
    if (shouldFocus && inputRef.current) inputRef.current.focus()
  }, [shouldFocus])

  const submit = async (e) => {
    e.preventDefault()
    try {
      await onSubmit(text.trim())
    } catch (error) {
      console.error('Error posting:', error)
    } finally {
      setText('')
    }
  }

  const onClick = () => {
    setExpanded(true)
  }

  const isInputEmpty = !Boolean(text)
  const isReplying = Boolean(replyingTo)

  return (
    <Container>
      {isReplying && expanded && (
        <span className="reply-to">
          Replying to <span className="reply-to--name">@{replyingTo}</span>
        </span>
      )}
      <Form
        minHeight={minHeight + 'px'}
        inline={!expanded}
        className={className}
        onSubmit={submit}
      >
        <div className="user">
          <UserImage
            src={feedUser?.data?.profile?.image}
            alt={feedUser?.data.name}
            username={feedUser?.data.username}
          />
        </div>
        <div className="input-section">
          <textarea
            ref={inputRef}
            onChange={(e) => handleInput(e.target.value)}
            placeholder={placeholder}
            value={text}
            onClick={onClick}
            onKeyDown={handleKeyDown}
          />
          {showDropdown && (
            <SuggestionDropdown
              suggestions={filteredSuggestions}
              activeIndex={activeSuggestionIndex}
              onClickSuggestion={(user) => {
                insertMention(user)
                inputRef.current.focus()
              }}
            />
          )}
          <div className="actions">
            <div className="right">
              <button
                type="submit"
                className="submit-btn"
                disabled={isInputEmpty}
              >
                {submitText}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  )
}
