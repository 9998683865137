import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStreamContext } from 'react-activity-feed'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import styled from 'styled-components'
import axios from 'axios'

import { updateProfileImage } from '../../contexts/auth/AuthSlice'
import { useAuth } from '../../contexts/auth/useAuth'

const Container = styled.div`
  display: flex;
  border-radius: 50%;
`

const StyledImage = styled.img`
  object-fit: cover;
  cursor: pointer;
  border-radius: 50%;
`

const StyledPlaceholderSVG = styled.svg`
  cursor: pointer;
  height: 100%;
  width: 100%;
`

const PlaceholderSVG = ({ onClick }) => (
  <StyledPlaceholderSVG
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
    viewBox="0 0 16 16"
  >
    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
    <path
      fillRule="evenodd"
      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
    />
  </StyledPlaceholderSVG>
)

const UserImage = ({
  src,
  alt,
  username,
  userId,
  clickable = true,
  expandable = false,
}) => {
  const navigate = useNavigate()
  const { authState } = useAuth()
  const { client } = useStreamContext()
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [key, setKey] = useState(0)
  const [error, setError] = useState(false)

  useEffect(() => {
    setTimeout(() => setKey(key + 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLightboxOpen])

  useEffect(() => {
    const handleImageReplace = async () => {
      if (userId && src && src.includes('googleusercontent.com')) {
        await uploadImageToStream(
          src,
          userId,
          client,
          authState.authUser.feedToken
        )
      }
    }

    handleImageReplace()
  }, [src, userId, client, authState.authUser.feedToken])

  const handleClick = (e) => {
    e.preventDefault()
    if (clickable && username) {
      e.stopPropagation()
      navigate(`/${username}`)
    } else if (expandable) {
      setIsLightboxOpen(true)
    }
  }

  const handleImageError = (e) => {
    setError(true)
  }

  return (
    <Container>
      {src && !error ? (
        <StyledImage
          src={src}
          alt={alt}
          onClick={handleClick}
          onError={handleImageError}
        />
      ) : (
        <PlaceholderSVG onClick={handleClick} />
      )}
      {isLightboxOpen && src && (
        <Lightbox
          key={src}
          mainSrc={src}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}
    </Container>
  )
}

const uploadImageToStream = async (imageUrl, userId, client, feedToken) => {
  try {
    const response = await axios({
      url: imageUrl,
      method: 'GET',
      responseType: 'blob',
    })

    const file = new File([response.data], 'profile.jpg', {
      type: response.data.type,
    })

    const streamResponse = await client.images.upload(file)
    const streamImageUrl = streamResponse.file

    await updateProfileImage({ userId, imageUrl: streamImageUrl }, feedToken)

    return streamImageUrl
  } catch (error) {
    console.error(
      'Error uploading image to Stream:',
      error.response ? error.response.data : error.message
    )
    return imageUrl // Fallback to the original URL if there's an error
  }
}

export default UserImage
