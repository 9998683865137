import { useState, useRef, useEffect, useCallback } from 'react'

export const useMentions = (chatClient) => {
  const [text, setText] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [debouncedTerm, setDebouncedTerm] = useState('')
  const timerIDRef = useRef(null)

  const handleInput = (inputValue) => {
    // console.log('Input value:', inputValue) // Log user input
    setText(inputValue)

    const lastChar = inputValue[inputValue.length - 1]
    const match = inputValue.match(/@(\w*)$/)

    if (lastChar === '@' || match) {
      const typedMention = match ? match[1] : ''
      // console.log('Typed mention:', typedMention) // Log the mention being typed
      setDebouncedTerm(typedMention)
    } else {
      setShowDropdown(false)
    }
  }

  const queryUsers = useCallback(
    async (term) => {
      if (!chatClient || !term) return []

      const filter = debouncedTerm
        ? {
            $or: [
              { name: { $autocomplete: debouncedTerm } },
              { id: { $autocomplete: debouncedTerm } },
            ],
            id: { $nin: [chatClient.userID, 'zacheryconverse'] },
          }
        : { id: { $nin: [chatClient.userID, 'zacheryconverse'] } }
      const sort = { last_active: -1 }
      const options = { limit: 5 }

      try {
        const response = await chatClient.queryUsers(filter, sort, options)
        // console.log('Users fetched:', response.users) // Log the users fetched
        return response.users || []
      } catch (error) {
        console.error('Error fetching users:', error)
        return []
      }
    },
    [chatClient, debouncedTerm]
  )

  useEffect(() => {
    if (debouncedTerm) {
      if (timerIDRef.current) {
        clearTimeout(timerIDRef.current)
      }

      timerIDRef.current = setTimeout(async () => {
        const users = await queryUsers(debouncedTerm)
        setFilteredSuggestions(users)
        setShowDropdown(users.length > 0)
      }, 300)
    } else {
      setShowDropdown(false)
    }

    return () => {
      clearTimeout(timerIDRef.current)
    }
  }, [debouncedTerm, queryUsers])

  const handleKeyDown = (e) => {
    if (showDropdown) {
      if (e.key === 'ArrowDown') {
        setActiveSuggestionIndex(
          (prevIndex) => (prevIndex + 1) % filteredSuggestions.length
        )
        e.preventDefault()
      } else if (e.key === 'ArrowUp') {
        setActiveSuggestionIndex(
          (prevIndex) =>
            (prevIndex - 1 + filteredSuggestions.length) %
            filteredSuggestions.length
        )
        e.preventDefault()
      } else if (e.key === 'Enter' || e.key === 'Tab') {
        insertMention(filteredSuggestions[activeSuggestionIndex])
        e.preventDefault()
      } else if (e.key === 'Escape') {
        setShowDropdown(false)
      }
    }
  }

  const insertMention = (user) => {
    // console.log('Mention selected:', user.username) // Log the mention selected
    setText((prevText) => {
      // console.log('prevText:', prevText, 'user.username:', user.username)
      return prevText.replace(/@\w*$/, `@${user.username} `)
    })
    setShowDropdown(false)
    // console.log('Text after mention:', text) // Log the text after mention
  }

  return {
    text,
    setText,
    handleInput,
    handleKeyDown,
    insertMention,
    filteredSuggestions,
    showDropdown,
    activeSuggestionIndex,
  }
}
