import React, { useRef, forwardRef } from 'react'
import { StatusUpdateForm } from 'react-activity-feed'
import 'react-activity-feed/dist/index.css'
import styled from 'styled-components'

import PostTextarea from './PostTextarea'

const ForwardedStatusUpdateForm = forwardRef((props, ref) => (
  <StatusUpdateForm {...props} innerRef={ref} />
))

const StyledStatusUpdateForm = styled(ForwardedStatusUpdateForm)`
  .raf-panel-header {
    border-bottom: 1px solid #333;
    padding: 5px;
  }

  .raf-button--primary {
    background-color: var(--theme-color);
    color: black;
    border-radius: 30px;
    transition: background-color 0.2s;

    &:disabled {
      background-color: #555;
    }

    &:hover {
      background-color: var(--faded-theme-color);
    }
  }

  .rta__textarea {
    background-color: black;
    border: none;
    padding: 15px;
    font-size: 20px;
    font-weight: 500;
    color: #e0e0e0;
    resize: none;
    overflow: hidden;

    &:focus {
      background-color: black;
      outline: 2px solid var(--theme-color);
      border-radius: 3px;
    }
  }
`

const CreatePostForm = (props) => {
  const formRef = useRef(null)

  return (
    <StyledStatusUpdateForm
      {...props}
      Textarea={(innerProps) => <PostTextarea {...innerProps} />}
      ref={formRef}
    />
  )
}

export default CreatePostForm
